import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import canoeBuild from "../images/canoe-building.jpg";

const Canoe = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>Va'a and Canoe Building</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="p-4">
            <p>
              For thousands of years, the people of Polynesia relied on the va'a
              as their primary mode of transportation across vast stretches of
              open ocean. These remarkable vessels were constructed using a
              range of traditional materials and techniques that have been
              passed down through generations.
            </p>

            <p>
              One of the key materials used in building va'a is the wood of the
              breadfruit tree. This wood is known for its strength, durability,
              and buoyancy, making it ideal for use in constructing seafaring
              vessels. Other traditional materials used in building va'a include
              coconut fiber rope, which was used to lash the hull and outrigger
              together, and pandanus leaves, which were woven together to create
              sails.
            </p>

            <p>
              The construction of a va'a was a highly specialized and revered
              art form that required great skill and knowledge. The process
              typically began with the selection of a suitable tree, which was
              felled and then carved into shape using a range of traditional
              tools, including adzes and chisels.
            </p>

            <p>
              Once the hull and outrigger had been constructed, they were lashed
              together using coconut fiber rope, and then the sails were added.
              These sails were often decorated with intricate designs and
              symbols that held great significance in Polynesian culture.
            </p>

            <p>
              In Polynesian culture, the va'a held great symbolism and
              significance, representing not only a means of transportation but
              also a connection to the natural world and the spiritual realm.
              The sail of the va'a, in particular, was seen as a powerful symbol
              of the winds and the elements, and many Polynesian myths and
              legends feature stories of va'a voyages and the heroic deeds of
              their crews.
            </p>

            <p>
              At our company, we are committed to honoring the rich history and
              traditions of Polynesian seafaring culture by building and
              maintaining va'a using these traditional materials and techniques.
              We believe that by doing so, we can help to keep this important
              cultural legacy alive and thriving for generations to come.
            </p>

            <p>
              Thank you for taking the time to learn more about the traditional
              techniques and materials used in building va'a. We invite you to
              explore our website further to learn more about our work and the
              history of Polynesian seafaring culture.
            </p>
          </Col>
          <Col md={6} className="p-3">
            <img
              src={canoeBuild}
              alt="Polynesian Oarsmen History and Heritage"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Canoe;
