import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import AboutPic from "../images/about-us.jpg";

const About = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>About Us</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="p-3">
            <img
              src={AboutPic}
              className="img-fluid"
              alt="Outrigger Canoe in Bay"
            />
          </Col>
          <Col md={6} className="p-3">
            <p>
              Welcome to Va'a Lifestyle, your go-to destination for all things
              related to Va'a racing! Our website is dedicated to providing you
              with the latest news, information, and resources on this exciting
              and growing sport.{" "}
            </p>

            <p>
              Our team is made up of passionate Va'a enthusiasts who have a deep
              love for the sport and a commitment to sharing their knowledge
              with others. We believe that Va'a racing is more than just a sport
              – it's a lifestyle that promotes fitness, health, and well-being.
            </p>

            <p>
              At Va'a Lifestyle, you'll find a wealth of information on
              everything from race schedules and results, to training tips and
              techniques, to equipment reviews and recommendations. We also
              feature articles and interviews with some of the top Va'a athletes
              from around the world, giving you a behind-the-scenes look at what
              it takes to succeed in this demanding and rewarding sport.
            </p>

            <p>
              Our goal is to create a community of like-minded individuals who
              share our passion for Va'a racing. Whether you're a seasoned
              veteran or just starting out, we welcome you to join us on this
              journey and explore all that the Va'a lifestyle has to offer.
            </p>

            <p>
              Thank you for visiting Va'a Lifestyle, and we look forward to
              sharing our love of Va'a racing with you!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
