import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import canoe from "../images/culture-canoe.jpg";

const Culture = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>Culture and Community</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={canoe} className="img-fluid" alt="polynesian canoe" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="p-3">
            <p>
              The Va'a Polynesian Oarsmen community is a group of individuals
              who are dedicated to the sport of Va'a or traditional Polynesian
              canoe racing. The community is deeply rooted in Polynesian culture
              and traditions, and the sport of Va'a has a long history in the
              Pacific Islands.
            </p>

            <p>
              Va'a racing involves teams of six paddlers who work together to
              navigate the canoe through the water using wooden paddles. The
              races are often held in open ocean conditions, and the paddlers
              must use their strength, stamina, and skill to overcome the waves
              and currents.
            </p>

            <p>
              Beyond the sport itself, the Va'a Polynesian Oarsmen community is
              a close-knit group that is committed to preserving and promoting
              Polynesian culture. The community is made up of individuals from
              diverse backgrounds who share a common love for the ocean and a
              deep respect for the traditions of the Polynesian people.
            </p>

            <p>
              The Va'a Polynesian Oarsmen community is deeply connected to the
              environment and to the ocean. They see themselves as stewards of
              the ocean and work to promote sustainable practices and
              environmental conservation.
            </p>

            <p>
              The community is also dedicated to passing down their knowledge
              and skills to future generations. They offer training and
              mentorship programs for young people, helping them to develop
              their paddling skills and learn about Polynesian culture and
              traditions.
            </p>

            <p>
              In addition to the sport of Va'a, the community also celebrates
              other aspects of Polynesian culture, including music, dance, and
              language. They participate in cultural festivals and events,
              sharing their traditions and heritage with others.M
            </p>

            <p>
              Overall, the Va'a Polynesian Oarsmen community is a vibrant and
              passionate group that is deeply connected to their culture and to
              the ocean. Through their love of the sport of Va'a and their
              commitment to preserving their traditions, they are helping to
              ensure that the legacy of the Polynesian people continues to
              thrive for generations to come.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Culture;
