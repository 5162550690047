import "./footer.css";
import { Container, Row, Col } from "react-bootstrap";
import facebook from "../../images/facebook.png";
import instagram from "../../images/instagram.png";
import twitter from "../../images/twitter.png";
import youtube from "../../images/youtube.png";

const Footer = () => {
  return (
    <div className="bg-color p-4">
      <Container>
        <Row>
          <Col className="p-3">
            <h3>VA'A VIBES</h3>
            <hr />
            <p>
              Va'a is deeply rooted in Polynesian culture, where it is
              considered a sacred activity that connects people to their
              ancestors and the ocean. The lifestyle of a va'a paddler involves
              a strong connection to nature, as they spend countless hours on
              the water, observing the tides, currents, and wildlife.
            </p>
          </Col>
          <Col className="p-3">
            <h3>CONTACT</h3>
            <hr />
            <p>
              12345 XYZ Road, STE #404
              <br />
              Miami, FL. 33142
            </p>
            <p>
              <strong>Phone:</strong>&nbsp;305.555.5555
            </p>
            <p>
              <strong>Email:</strong>
              <a className="link" href="mailto:info@vaalifestyle.com">
                &nbsp;info@vaalifestyle.com
              </a>
            </p>
          </Col>
          <Col className="p-3">
            <h3>SOCIAL MEDIA</h3>
            <hr />
            <p>Follow our team on Social Media at:</p>
            <Row className="mt-3">
              <Col md={2}>
                <img
                  src={facebook}
                  className="img-fluid"
                  alt="Follow Us on Facebook"
                />
              </Col>
              <Col md={2}>
                <img
                  src={instagram}
                  className="img-fluid"
                  alt="Follow Us on Instagram"
                />
              </Col>
              <Col md={2}>
                <img
                  src={twitter}
                  className="img-fluid"
                  alt="Follow Us on Twitter"
                />
              </Col>
              <Col md={2}>
                <img
                  src={youtube}
                  className="img-fluid"
                  alt="Follow Us on Youtube"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
