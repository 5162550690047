import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navigation/Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/Footer/Footer";
import { Container } from "react-bootstrap";
import Homepage from "./components/Homepage/Homepage";
import About from "../src/Pages/About";
import Lifestyle from "../src/Pages/Lifestyle";
import Events from "../src/Pages/Events";
import Contact from "../src/Pages/Contact";
import Shop from "../src/Pages/Shop";
import History from "../src/Pages/History";
import Canoe from "../src/Pages/Canoe";
import Wayfinding from "../src/Pages/Wayfinding";
import Culture from "../src/Pages/Culture";
import Training from "../src/Pages/Training";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Navigation />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/About" element={<About />} />
          <Route path="/Lifestyle" element={<Lifestyle />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Shop" element={<Shop />} />
          <Route path="/History" element={<History />} />
          <Route path="/Canoe" element={<Canoe />} />
          <Route path="/Wayfinding" element={<Wayfinding />} />
          <Route path="/Culture" element={<Culture />} />
          <Route path="/Training" element={<Training />} />
        </Routes>
        <Container fluid className="border-bg"></Container>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
