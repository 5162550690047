import { Container, Row, Col } from "react-bootstrap";
import "./event.css";

const Event = () => {
  return (
    <div>
      <Container className="my-4">
        <Row>
          <Col className="text-center">
            <h2>Live VA'A Racing Events</h2>
          </Col>
        </Row>
        <Row className="p-5">
          <Col md={6}>
            <h4 className="race">
              Experience the thrill of Va'a canoe racing!
            </h4>
            <p>
              Aloha friends! I'm excited to announce that VA'A Vibes will be
              live streaming the "2023 VA'A California Series Race #3" today!
              Join us on our YouTube channel at https://tinyurl.com/VAAVIBES at
              8:45 A.M (PST) to watch the top Va'a teams and paddlers compete
              for the championship title. It's going to be an epic day of racing
              and positive energy - you won't want to miss it! See you out on
              the water
            </p>

            <p>
              #vaa #outriggercanoe #paddleohana #paddlinglife #vibes
              #californiaseries #livestream
            </p>
          </Col>
          <Col md={6} className="p-4">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/xVLlMC0nW4s"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Event;
