import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import Life from "../images/vaa-lifestyle.jpg";
import LifeII from "../images/vaa-lifestyle-II.jpg";
import LifeIII from "../images/vaa-lifestyle-III.jpg";

const Lifestyle = () => {
  return (
    <div className="pageTop">
      <Container>
        <Row>
          <Col>
            <h2>Va'a Lifestyle</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6} className="p-4">
            <p>
              The Va'a racing lifestyle is more than just a sport – it's a way
              of life that promotes physical fitness, mental toughness, and a
              deep connection to nature. This thrilling water sport involves
              paddling a traditional outrigger canoe through rough ocean waters,
              challenging the paddlers' strength, skill, and endurance.
            </p>

            <p>
              Va'a racing requires a great deal of dedication, discipline, and
              teamwork, as paddlers must work together in perfect synchronicity
              to navigate the waves and cross the finish line. But it also
              offers a sense of community and camaraderie, as paddlers bond over
              their shared love of the sport and their respect for the natural
              world.
            </p>

            <p>
              For those who embrace the Va'a racing lifestyle, the rewards are
              many – improved physical fitness, a deep sense of accomplishment,
              and a connection to a rich cultural heritage. Whether you're a
              seasoned pro or just starting out, there's no better way to
              experience the thrill of the open ocean and the joy of working
              together with a team towards a common goal.
            </p>

            <p>
              The Va'a racing lifestyle is also deeply rooted in the cultural
              traditions of the Pacific Islands, where the sport originated
              centuries ago. Today, it has become a global phenomenon, with
              races and competitions held in countries around the world.
            </p>

            <p>
              But even as the sport has grown and evolved, its core values
              remain the same – respect for the ocean, respect for one's fellow
              paddlers, and a commitment to excellence in all aspects of the
              sport. Whether you're training for your first race or competing at
              the highest level, the Va'a racing lifestyle demands a level of
              dedication, perseverance, and mental toughness that sets it apart
              from other sports.
            </p>

            <p>
              At its heart, the Va'a racing lifestyle is about more than just
              winning – it's about pushing yourself to be the best you can be,
              and sharing that journey with others who share your passion.
              Whether you're seeking a physical challenge, a sense of community,
              or simply a way to connect with the natural world, the Va'a racing
              lifestyle offers something for everyone.
            </p>
          </Col>
          <Col md={6}>
            <img
              src={Life}
              className="img-fluid"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
            <img
              src={LifeII}
              className="img-fluid pt-4"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
            <img
              src={LifeIII}
              className="img-fluid pt-4"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Lifestyle;
