import { Container, Col } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./navigation.css";
import { Link } from "react-router-dom";

const Navigation = () => {
  return (
    <>
      {["lg"].map((expand) => (
        <Navbar key={expand} expand={expand} className="fixed-top mb-3">
          <Container fluid className="p-3">
            <Col xs={9} lg={2} className="text-start">
              <Navbar.Brand className="brand" href="/">
                VA'A VIBES
              </Navbar.Brand>
            </Col>
            <Col xs={3} lg={10}>
              <Navbar.Toggle
                className="bg-light me-2"
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
                className="bg-dark"
              >
                <Offcanvas.Header
                  closeButton
                  className="btn-close-white"
                ></Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 text-white fw-bold">
                    <Link className="link" exact to="/">
                      HOME
                    </Link>
                    <Link className="link" to="/About">
                      ABOUT
                    </Link>
                    <Link className="link" to="/Lifestyle">
                      LIFESTYLE
                    </Link>
                    <Link className="link" to="/Events">
                      EVENTS
                    </Link>
                    <Link className="link" to="/Contact">
                      CONTACT
                    </Link>
                    <Link className="link" to="/Shop">
                      SHOP
                    </Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Col>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Navigation;
