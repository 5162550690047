import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import boat from "../images/history-and-heritage.jpg";

const History = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>History and Heritage</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="p-4">
            <p>
              Welcome to our page dedicated to exploring the rich history and
              heritage of Polynesian oarsmen culture. From its origins to the
              present day, this culture has thrived and evolved through its
              seafaring traditions, art, and architecture.
            </p>

            <p>
              The Polynesian oarsmen are renowned for their expertise in
              navigating the vast Pacific Ocean, using only the stars, currents,
              and winds as their guide. This seafaring tradition dates back
              thousands of years, and the Polynesians' navigation skills were
              crucial to their survival and migration throughout the Pacific
              region.
            </p>

            <p>
              The art and architecture of the Polynesians are also a significant
              part of their cultural heritage. The intricate carvings on their
              canoes, the woven mats, and tapa cloth, the tattoo designs, and
              the impressive structures of their villages are all examples of
              the Polynesian's skilled craftsmanship and unique aesthetics.
            </p>

            <p>
              The Polynesians' cultural evolution has been shaped by various
              factors, including colonization, globalization, and modernization.
              The influence of European explorers and settlers introduced new
              technologies, religions, and ideas that have influenced the
              Polynesian way of life. Despite these changes, the Polynesians
              have managed to preserve their cultural identity and continue to
              celebrate their traditions through various cultural festivals,
              music, dance, and other forms of art.
            </p>

            <p>
              Today, the Polynesian culture remains vibrant and diverse, with
              communities scattered across the Pacific region and diaspora
              communities around the world. The Polynesians have made
              significant contributions to the world's cultural heritage, and
              their seafaring traditions, art, and architecture continue to
              inspire and fascinate people from different parts of the world.
            </p>

            <p>
              We invite you to join us on this journey of exploring the rich
              history and heritage of Polynesian oarsmen culture. Through our
              website, you will learn more about the Polynesians' fascinating
              history, their unique seafaring traditions, their remarkable art
              and architecture, and how their culture has evolved over time.
              Join us as we celebrate the Polynesian culture and the people who
              have kept it alive for generations.
            </p>
          </Col>
          <Col md={6} className="p-3">
            <img
              src={boat}
              alt="Polynesian Oarsmen History and Heritage"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default History;
