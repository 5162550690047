import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "./goods.css";
import history from "../../images/outrigger-canoe.jpg";
import building from "../../images/canoe-history-building.jpg";
import navway from "../../images/navigation.jpg";
import culture from "../../images/team-race-oarsmen.jpg";
import training from "../../images/canoe-hawaii.jpg";
import { Link } from "react-router-dom";

const Goods = () => {
  return (
    <div>
      <Container fluid className="border-bg"></Container>
      <Container>
        <Row className="mt-5">
          <Col className="text-center">
            <h2>VA'A VIBES</h2>
            <hr />
            <p>
              At Va'a Lifestyle, you'll find a wealth of resources and
              information to help you get involved in Va'a canoe racing, from
              training tips and equipment reviews to race schedules and live
              streams. But we're more than just a sports website - we're also a
              community of paddlers, enthusiasts, and cultural ambassadors who
              share a passion for Va'a and the way of life it represents.
              Through our articles, interviews, and multimedia content, we aim
              to showcase the beauty and diversity of Pacific island culture,
              while also exploring the challenges and opportunities facing these
              communities in the modern world.
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={4}>
            <Card className="mb-5">
              <Card.Img variant="top" src={history} />
              <Card.Body>
                <Card.Title>History and Heritage</Card.Title>
                <Card.Text>
                  Explore the rich history and heritage of Polynesian oarsmen
                  culture, from its origins to the present day. Learn about the
                  seafaring traditions, art, and architecture of the Polynesians
                  and how their culture has evolved over time.
                </Card.Text>
                <Link to="/History">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-5">
              <Card.Img variant="top" src={building} />
              <Card.Body>
                <Card.Title>Va'a and Canoe Building</Card.Title>
                <Card.Text>
                  Discover the traditional techniques and materials used in
                  building vaa, the outrigger canoes that were central to
                  Polynesian seafaring culture. Discover the symbolism and
                  significance of these vessels in Polynesian culture.
                </Card.Text>
                <Link to="/Canoe">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-5">
              <Card.Img variant="top" src={navway} />
              <Card.Body>
                <Card.Title>Navigation and Wayfinding</Card.Title>
                <Card.Text>
                  Explore the unique navigation and wayfinding techniques used
                  by Polynesian oarsmen to navigate vast distances on the open
                  ocean. Discover the importance of reading the stars, winds,
                  and currents in wayfinding.
                </Card.Text>
                <Link to="/Wayfinding">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Card className="mb-5">
              <Card.Img variant="top" src={culture} />
              <Card.Body>
                <Card.Title>Culture and Community</Card.Title>
                <Card.Text>
                  Discuss the strong cultural and community ties that are
                  intrinsic to Polynesian oarsmen culture and how these values
                  have been preserved and adapted over time. Learn about the
                  role of family, community, and tradition in the lives of
                  Polynesian communities.
                </Card.Text>
                <Link to="/Culture">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-5">
              <Card.Img variant="top" src={training} />
              <Card.Body>
                <Card.Title>Physical Training and Conditioning</Card.Title>
                <Card.Text>
                  Highlight the rigorous physical training and conditioning that
                  Polynesian oarsmen undergo to prepare for long-distance
                  voyages and races. Learn about the values of toughness,
                  resilience, and teamwork.
                </Card.Text>
                <Link to="/Training">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid className="border-bg"></Container>
    </div>
  );
};

export default Goods;
