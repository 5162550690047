import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import train from "../images/training-canoe-racers.jpg";

const Training = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>Physical Training and Conditioning</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={train} className="img-fluid" alt="polynesian canoe" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="p-3">
            <p>
              Va'a canoeing, also known as outrigger canoeing, is a water sport
              that requires a significant amount of physical training and
              conditioning. It involves paddling a canoe with an attached
              outrigger, which helps with stability and balance, in the open
              water.
            </p>

            <p>
              The physical demands of va'a canoeing require paddlers to have a
              high level of fitness and endurance. To be successful in the
              sport, paddlers need to have strong upper body and core muscles,
              as well as good cardiovascular endurance. Additionally, the sport
              requires paddlers to have good balance, coordination, and
              flexibility.
            </p>

            <p>
              A well-rounded training program for va'a canoeing should focus on
              building strength and endurance, as well as improving balance and
              coordination. Training can be broken down into four main
              categories: cardio, strength, technique, and flexibility.
            </p>

            <p>
              Cardiovascular endurance is crucial for va'a canoeing. The sport
              requires paddlers to maintain a high level of effort over long
              periods of time, and this requires a strong cardiovascular system.
              Paddlers should engage in activities such as running, cycling, and
              swimming to build their endurance.
            </p>

            <p>
              Strength training is also important for va'a canoeing. Paddlers
              need to have strong upper body and core muscles to be able to
              paddle effectively. Exercises such as push-ups, pull-ups, and
              planks can help build strength in these areas.
            </p>

            <p>
              Technique training is essential for improving paddling skills and
              efficiency. This involves working on proper paddling technique, as
              well as balance and coordination drills. Paddlers can also benefit
              from practicing on land using a paddle machine or indoor rowing
              machine.
            </p>

            <p>
              Flexibility is also important for va'a canoeing. Paddlers need to
              be able to move their bodies freely and easily in the canoe, and
              flexibility can help prevent injury. Stretching exercises such as
              yoga and Pilates can help improve flexibility.
            </p>

            <p>
              Overall, a well-rounded training program that focuses on cardio,
              strength, technique, and flexibility is essential for success in
              va'a canoeing. By committing to a training program, paddlers can
              improve their endurance, strength, balance, and coordination,
              which will help them become better athletes in the sport.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Training;
