import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";

const Shop = () => {
  return (
    <div className="pageTop">
      <Container>
        <Row>
          <Col>
            <h2>Shop</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col md={6} className="p-4">
            <p>Dear valued customers,</p>

            <p>
              We are thrilled to announce that VAALifestyle.com will soon be
              launching our very own E-commerce platform! Our team has been
              working tirelessly behind the scenes to bring you a seamless
              online shopping experience.
            </p>

            <p>
              From the comfort of your own home, you'll be able to browse our
              extensive range of products and purchase them with just a few
              clicks. Our E-commerce platform will feature a wide variety of
              high-quality products at competitive prices, including clothing,
              accessories, and more.
            </p>

            <p>
              We understand the importance of convenience and safety in today's
              world, which is why our E-commerce platform will be designed with
              your needs in mind. With secure payment options and fast shipping,
              you can rest assured that your shopping experience with us will be
              hassle-free and enjoyable.
            </p>

            <p>
              We are excited to share this news with you and can't wait for you
              to experience our new E-commerce platform. Stay tuned for further
              updates on the launch date and exciting promotions!
            </p>

            <p>Thank you for your continued support of VAALifestyle.com.</p>

            <p>
              Best regards,
              <br />
              The VAALifestyle Team
            </p>
          </Col>
          <Col md={6} className="p-4">
            <h2>COMING SOON</h2>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Shop;
