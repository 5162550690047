import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import Life from "../images/vaa-lifestyle.jpg";

const Events = () => {
  return (
    <div className="pageTop">
      <Container>
        <Row>
          <Col>
            <h2>Events</h2>
            <hr className="lineColor" />
            <h2>EVENTS COMING SOON</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <img
              src={Life}
              className="img-fluid"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
          </Col>
          <Col md={6}>
            <img
              src={Life}
              className="img-fluid"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
          </Col>
        </Row>
        <Row className="my-5">
          <Col md={6}>
            <img
              src={Life}
              className="img-fluid"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
          </Col>
          <Col md={6}>
            <img
              src={Life}
              className="img-fluid"
              alt="VA'A Lifestlye Outrigger Canoes"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Events;
