import { Container, Row, Col } from "react-bootstrap";
import "../Midsection/midsection.css";

const Midsection = () => {
  return (
    <div>
      <Container className="midsection" fluid>
        <Row className="p-5">
          <Col md={5}>
            <h3>A Thrilling Water Sport</h3>
            <hr />
            <p className="text-white">
              Va'a canoe racing, also known as outrigger canoe racing, is a
              thrilling water sport that involves paddling a long, narrow canoe
              with an outrigger attached to one side. This traditional sport
              originated in the Pacific islands, and has gained popularity
              around the world, with competitions held in places like Hawaii,
              Tahiti, Australia, and the United States.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="border-bg"></Container>
    </div>
  );
};

export default Midsection;
