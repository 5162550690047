import { Container, Row, Col } from "react-bootstrap";
import "./jumbotron.css";

const Jumbotron = () => {
  return (
    <div>
      <Container className="JumboBG" fluid>
        <div className="Overlay">
          <Row>
            <Col md={6} className="text-white p-5">
              <h1>WELCOME TO VA'A VIBES</h1>
              <hr />
              <p>
                Your go-to source for all things related to Va'a canoe racing
                and the Pacific island culture that inspired it. Our website is
                dedicated to sharing the beauty and excitement of this unique
                water sport with the world, while also celebrating the rich
                history and traditions of the communities that have kept it
                alive for generations.
              </p>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Jumbotron;
