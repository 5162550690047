import Jumbotron from "../Jumbotron/Jumbotron";
import Goods from "../Goods/Goods";
import Midsection from "../Midsection/Midsection";
import Event from "../Event/Event";

const Homepage = () => {
  return (
    <div>
      <Jumbotron />
      <Event />
      <Goods />
      <Midsection />
    </div>
  );
};

export default Homepage;
