import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import contactBoat from "../images/contact-us.jpg";

const Contact = () => {
  return (
    <div className="pageTop">
      <Container>
        <Row>
          <Col>
            <h2>Contact Us</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6} className="p-4">
            <iframe
              width="100%"
              height="100%"
              src="http://api.vaalifestyle.com/contact-2"
              title="Contact Form"
            ></iframe>
          </Col>
          <Col md={6} className="p-4">
            <p>
              Thank you for your interest in vaalifestyle.com! We are always
              happy to hear from our visitors and potential customers.
            </p>

            <p>
              If you have any questions, comments, or concerns about our
              products or services, please don't hesitate to get in touch with
              us. Our dedicated customer service team is here to assist you and
              address any issues you may have.
            </p>

            <p>
              You can reach us by filling out the contact form on our website or
              by sending an email to support@vaalifestyle.com. We aim to respond
              to all inquiries within 24 hours, so you can expect a prompt and
              helpful reply from us.
            </p>

            <p>
              We appreciate your interest in our brand and look forward to
              hearing from you!
            </p>

            <p>
              Best regards,
              <br />
              The team at vaalifestyle.com
            </p>
            <img
              src={contactBoat}
              className="img-fluid"
              alt="Outrigger Canoe in Water with Passengers"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Contact;
