import { Container, Row, Col } from "react-bootstrap";
import "../Pages/pages.css";
import navjumbo from "../images/nav-wayfinding.jpg";

const Wayfinding = () => {
  return (
    <div className="pageTop">
      <Container className="my-5">
        <Row>
          <Col>
            <h2>Navigation and Wayfinding</h2>
            <hr className="lineColor" />
          </Col>
        </Row>
        <Row>
          <Col>
            <img src={navjumbo} className="img-fluid" alt="boat at a dock" />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="p-3">
            <p>
              VA'A Navigation and Wayfinding is a traditional method of
              navigation used by the Polynesian people to travel vast distances
              across the Pacific Ocean. It involves observing the stars, winds,
              currents, and waves to determine the position and direction of the
              canoe, or va'a. The va'a is a significant symbol of Polynesian
              culture, representing the connection between the people, the
              ocean, and the environment.
            </p>

            <p>
              The art of Polynesian navigation and wayfinding has a rich history
              dating back thousands of years. It was used by Polynesian
              explorers to colonize the Pacific islands and to establish trade
              and cultural connections across the ocean. The navigators used
              their knowledge of the natural world and their intuition to guide
              them, without the aid of modern navigational tools such as GPS or
              compasses.
            </p>

            <p>
              Today, VA'A Navigation and Wayfinding is still practiced by a
              small group of skilled navigators, who are known as "wayfinders."
              They are dedicated to preserving the traditional techniques and
              knowledge of Polynesian navigation and passing it down to future
              generations. These wayfinders continue to navigate the ocean using
              only their observations of the natural world, without relying on
              modern navigational equipment.
            </p>

            <p>
              The significance of VA'A Navigation and Wayfinding goes beyond its
              practical applications. It represents a way of life that is deeply
              connected to the environment and to the cultural heritage of the
              Polynesian people. It is a testament to the ingenuity and
              resourcefulness of the human spirit and a reminder of the
              importance of respecting and preserving our natural world.
            </p>

            <p>
              Overall, VA'A Navigation and Wayfinding is an incredible example
              of the human ability to connect with and navigate the natural
              world. Its enduring legacy serves as an inspiration for those who
              seek to explore and understand the vast and mysterious ocean.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Wayfinding;
